import React from "react"
import { Container, Grid, Typography } from "@mui/material"
import Layout from "../components/Layout/Layout"
import PageTitle from "../components/PageTitle/PageTitle"
import "./style/newsFeed.scss"
import { Helmet } from "react-helmet"
function SingleNewFeedPage({ pageContext }) {
  const singlePage = pageContext
  const searchIndices = [
    {
      name: `CassandraFeeds`,
      title: `News Feed`,
      hitComp: `CassandraFeedsHit`,
    },
  ]
  return (
    <Layout>
      <Helmet>
        <title>Cassandra Link - {singlePage.title}</title>
        <meta name="description" content={singlePage.summary} />
        <meta property="og:description" content={singlePage.summary} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={singlePage.link} />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={singlePage.title} />
        <meta name="twitter:description" content={singlePage.summary} />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Contact - Cassandra Link",
            keywords: "Apache Cassandra",
            author: {
              "@type": "Organization",
              name: "Apache Cassandra",
            },
          })}
        </script>
        <meta property="og:title" content={singlePage.title} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <Grid>
        <PageTitle variant="h1" title={singlePage.title} />
        <Container maxWidth="lg">
          <Grid>
            <Typography gutterBottom>Author: {singlePage.author}</Typography>
            <Typography>
              Originally Sourced from:{" "}
              <a href={singlePage.link}> {singlePage.link} </a>
            </Typography>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid
            className="content"
            dangerouslySetInnerHTML={{
              __html: singlePage.content,
            }}
          />
        </Container>
      </Grid>
    </Layout>
  )
}

export default SingleNewFeedPage
